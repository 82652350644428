import React from "react";

import { Helmet } from "react-helmet";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

import "./App.css";

import metadata from "./metadata";

import MainRouter from "./MainRouter";

function ScrollToTop() {
  const history = useHistory();

  React.useEffect(() => {
    return history.listen(() => {
      switch (history.action) {
        case "PUSH":
        case "REPLACE": {
          window?.scrollTo(0, 0);
          (document?.activeElement as any)?.blur?.();
          break;
        }
        case "POP": {
          //
        }
      }
    });
  }, [history]);

  return null;
}

function App() {
  return (
    <>
      <Helmet
        defaultTitle={metadata.appName}
      >
        <meta name="author" content={metadata.author} />
      </Helmet>
      <Router>
        <ScrollToTop />
        <MainRouter />
      </Router>
    </>
  );
}

export default App;
