import React from "react";

import { Container } from "react-bootstrap";

interface PropsType {
  title: string;
  img: string;
}

export default function PlaceholderPage(props: React.PropsWithChildren<PropsType>) {
  const { title, img, children } = props;

  return (
    <Container className="text-center" style={{ paddingTop: "5em", paddingBottom: "5em" }}>
      <img src={img} alt={title} style={{ maxWidth: "50%" }} />
      <p className="lead mt-5" style={{ fontSize: "2em" }}>
        {title}
      </p>
      {children}
    </Container>
  );
}

