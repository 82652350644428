import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench, faComment, faEnvelope, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faMastodon, faReddit } from "@fortawesome/free-brands-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

import supportButton from "./images/support-button.svg";

import "./SupportButton.css";

import Link from "./widgets/SmartLink";
import metadata from "./metadata";

export default function SupportButton() {
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <>
      {menuOpen && (
        <div id="support_bubble">
          <button id="support_bubble_close" type="button" className="close" aria-label="Close" onClick={() => setMenuOpen(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="clearfix"></div>
          <div className="bubble_content">
            <h3>Need help?</h3>
            <p>We are here for you.</p>

            <Link href={metadata.links.docs} target="_blank" className="btn btn-primary w-100"><FontAwesomeIcon className="icon-left" icon={faFileAlt} /> Documentation</Link>
            <Link href={metadata.links.chat} target="_blank" className="btn btn-info w-100"><FontAwesomeIcon className="icon-left" icon={faComment} /> Chat With Us</Link>
            <Link href={metadata.links.sources.main} target="_blank" className="btn btn-secondary w-100"><FontAwesomeIcon className="icon-left" icon={faWrench} /> Report Issue</Link>
            <Link href={`mailto:${metadata.email.contact}`} target="_blank" className="btn btn-secondary w-100"><FontAwesomeIcon className="icon-left" icon={faEnvelope} /> Email Us</Link>

            <div className="bubble_social d-flex justify-content-around">
              <Link href={metadata.reddit.link} title="reddit"><FontAwesomeIcon icon={faReddit} /></Link>
              <Link href={metadata.mastodon.link} title="Mastodon"><FontAwesomeIcon icon={faMastodon} /></Link>
              <Link href={metadata.twitter.link} title="Twitter"><FontAwesomeIcon icon={faTwitter} /></Link>
            </div>
          </div>
        </div>
      )}

      <a id="support_button" href="/about#contact" className="d-flex justify-content-center align-items-center" title="Get help"
        onClick={(event) => {
          setMenuOpen(!menuOpen);
          event.preventDefault();
        }}
      >
        <img alt="Support button" src={supportButton} />
      </a>
    </>
  );
}

