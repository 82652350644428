export default {
  author: "EteSync Ltd",
  appName: "Etebase",
  tagline: "Your end-to-end encrypted backend",
  description: "An open-source and end-to-end encrypted SDK and backend",
  keywords: "encryption, end-to-end, etebase, firebase, backend, sync, zero-knowledge, cryptography",
  baseUrl: "https://www.etebase.com",
  links: {
    sources: {
      main: "/#open-source",
      github: "https://github.com/etesync",
      server: "https://github.com/etesync/server",
      js: "https://github.com/etesync/etebase-js",
      rust: "https://github.com/etesync/etebase-rs",
      java: "https://github.com/etesync/etebase-java",
      python: "https://github.com/etesync/etebase-py",
      c: "https://github.com/etesync/libetebase",
      go: "https://github.com/etesync/etebase-go",
      ruby: "https://github.com/etesync/etebase-ruby",
      swift: "https://github.com/etesync/etebase-swift",
      csharp: "https://github.com/etesync/etebase-csharp",
    },
    etesync: "https://www.etesync.com",
    blog: "https://blog.etesync.com/tag/etebase/",
    chat: "https://www.etebase.com/community-chat/",
    docs: "https://docs.etebase.com/",
    signup: "https://www.etebase.com/signup/",
    dashboard: "https://devs.etebase.com/devs/dashboard/",
  },
  pgpKey: {
    key: "0x9E21F091FC395F366A4743E2D2E584C37C477933",
    link: "http://keys.gnupg.net/pks/lookup?op=get&search=0x9E21F091FC395F366A4743E2D2E584C37C477933",
  },
  twitter: {
    handle: "@EteSync",
    link: "https://twitter.com/EteSyncHQ",
  },
  mastodon: {
    handle: "@EteSync@mastodon.social",
    link: "https://mastodon.social/@EteSync",
  },
  reddit: {
    handle: "r/EteSync",
    link: "https://www.reddit.com/r/EteSync/",
  },
  email: {
    contact: "contact@etebase.com",
    sales: "sales@etebase.com",
    jobs: "jobs@etebase.com",
    partner: "partner@etebase.com",
    press: "press@etebase.com",
    conduct: "conduct@etebase.com",
  },
};
