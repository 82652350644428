import React from "react";

import { Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import MetaHeader from "../MetaHeader";
import metadata from "../metadata";

export default function Roadmap() {
  const title = "Roadmap";

  return (
    <>
      <MetaHeader title={title} />

      <Container className="py-5">
        <div className="article-content">
          <h1 className="display-3 font-weight-bold pb-5">{title}</h1>
          <ReactMarkdown plugins={[gfm]} escapeHtml={false} source={`
This is a list of all the major tasks we are working on at Etebase.

Etebase is currently in beta. What does that mean? It means that some of the things we would like to have are not there yet, though what's there is already solid, and used in production.

Got any suggestions or feedback? Please let us know by either [reaching out](/about#contact), or replying directly on the relevant Github issues or pull requests. Interested in helping? Please let us know!

## Planned
- [ ] Database API - an easy API to manipulate objects (e.g. JSON objects) directly, including filtering, sorting and etc. This is in contrast to the more key-value store nature of the current API.
- [ ] Offline support - save changes locally when there's no connection and upload them once online.
- [ ] Improve admin dashboard - make it easier to manage your users
- [ ] Swift library ([repo](https://github.com/etesync/etebase-swift/))
- [ ] Dart library

## In Progress
- [ ] Make it easier to handle very large files
- [ ] Messaging - send end-to-end encrypted messages to other users in real-time.
- [ ] Go library ([code](https://github.com/etesync/etebase-go))

## Proposals
Please refer to [the protocol proposals tracker](https://github.com/etesync/etebase-docs/issues?q=is%3Aopen+is%3Aissue+label%3Aproposal) for a list of protocol proposals.

## Done
- [X] Database subscriptions - get notified when items change intsead of polling.
- [X] Etebase-JS: automatically use a web worker for slow cryptographic operations
- [X] C# library ([pull request](https://github.com/etesync/etebase-csharp/pull/1/))
- [X] Move the server to [FastAPI](https://fastapi.tiangolo.com/) for increased performance and development speed.
- [X] Implement \`fetchMulti\` for fetching mulitple items by \`uid\`.
- [X] Docs: add [website](${metadata.links.docs}) with docs for JavaScript, Python, Java, Kotlin and C/C++.
  - [X] Add Rust docs [etebase-docs#10](https://github.com/etesync/etebase-docs/pull/10)
  - [X] Add protocol specifications for authentication and the encryption
- [X] [CollectionTypes](https://docs.etebase.com/overview#collection-type) - for easier collection filtering.
- [X] Launch public beta. 🎉🎉🎉
          `.trim()} />
        </div>
      </Container>
    </>
  );
}
