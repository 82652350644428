import React from "react";

import imageNotFound from "../images/not_found.svg";

import PlaceholderPage from "../PlaceholderPage";

export default function NotFound404() {
  const title = "Page not found.";

  return (
    <PlaceholderPage title={title} img={imageNotFound} />
  );
}

