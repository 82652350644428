import React from "react";

import { Container } from "react-bootstrap";

import MetaHeader from "../MetaHeader";

export default function Imprint() {
  const title = "Imprint";
  const address = `
Union House,
111 New Union Street,
Coventry, England,
CV1 2NT
  `.trim();

  return (
    <>
      <MetaHeader title={title} />

      <Container className="py-5">
        <div className="article-content">
          <h1 className="display-3 font-weight-bold pb-5">{title}</h1>
          <p>
            EteSync Ltd
            <br />
            <small className="text-muted">Company number 12122991</small>
          </p>

          <pre>{address}</pre>

          <h2>Servers</h2>
          <p>
            <a href="https://hetzner.com">Hetzner</a> - Germany
            <br />
            <a href="https://edis.at">EDIS</a> - Austria
          </p>
        </div>
      </Container>
    </>
  );
}
