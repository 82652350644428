import React from "react";

import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import metadata from "./metadata";

import logo from "./images/logo192.png";

interface PropsType {
  title?: string;
  description?: string;
}

export default function MetaHeader(props: PropsType) {
  const location = useLocation();
  const title = (props.title) ?
    `${metadata.appName} · ${props.title}` :
    `${metadata.appName} - ${metadata.tagline}`;
  const description = props.description ?? metadata.description;

  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={metadata.twitter.handle} />
      <meta property="og:title" content={`${title}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={metadata.appName} />
      <meta property="og:url" content={metadata.baseUrl + location.pathname} />
      {description && (
        <meta property="og:description" content={description} />
      )}
      <meta property="og:image" content={`${metadata.baseUrl}${logo}`} />
      <meta name="og:image:alt" content={`${metadata.appName} logo`} />
    </Helmet>
  );
}
