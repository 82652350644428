import React from "react";

import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import { darcula as style } from "react-syntax-highlighter/dist/esm/styles/prism";

SyntaxHighlighter.registerLanguage("javascript", js);

interface PropsType {
  code: string;
  title?: string;
  className?: string;
}

export default function Code(props: PropsType) {
  const { code, title, className } = props;
  return (
    <div className={"rounded " + className ?? ""} style={{ backgroundColor: "#2b2b2b" }}>
      {title && (
        <div style={{
          color: "#ccc",
          textAlign: "left",
          borderBottom: "1px solid #444",
          padding: "0.5em 1em",
          fontSize: "87.5%",
        }}>
          {title}
        </div>
      )}
      <SyntaxHighlighter className="rounded" language="javascript" style={style}>
        {code}
      </SyntaxHighlighter>
    </div>
  );
}

