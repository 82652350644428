import React from "react";

import { Container, Row, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faLock, faChessRook, faCandyCane, faShieldAlt, faUsers, faHeart, faHourglassHalf, faTachometerAlt, faDumbbell, faMoneyBill, faBalanceScale, faCopyright } from "@fortawesome/free-solid-svg-icons";
import { faJs, faPython, faOsi, faRust, faJava, faSwift } from "@fortawesome/free-brands-svg-icons";

import csharpLogo from "../images/csharp-logo.svg";
import golangLogo from "../images/golang-logo.svg";
import rubyLogo from "../images/ruby-logo.svg";
import gnomeLogo from "../images/gnome-logo.svg";
import kdeLogo from "../images/kde-logo.svg";
import tasksOrgLogo from "../images/tasksorg.png";
import etesyncBrand from "../images/etesync-brand.svg";
import nlnetBrand from "../images/nlnet.svg";
import ngiBrand from "../images/ngi0.svg";
import svixBrand from "../images/svix.svg";
import metadata from "../metadata";

import CodeExamplesSection from "./Index/CodeExamplesSection";

import ClickableCard from "../widgets/ClickableCard";
import Code from "../widgets/Code";
import Link from "../widgets/SmartLink";
import MetaHeader from "../MetaHeader";

const mainCode = `
// Setup encryption and login to server
const etebase = await Etebase.Account.login("username", "password");
const collectionManager = etebase.getCollectionManager();

// Create, encrypt and upload a new collection
const collection = await collectionManager.create(
  "collection.type",
  { name: "My data" },
  "My private data!"
);
await collectionManager.upload(collection);
`.trim();

export default function Index() {
  const keyFeatures = [
    {
      title: "Strong Encryption",
      icon: faLock,
      content: (
        <>
          Etebase is built using <Link href="https://blog.etesync.com/end-to-end-encryption-what-it-is-and-why-it-is-needed/">end-to-end encryption</Link> and modern cryptography, so your users can be sure that only they can access their data.
        </>
      ),
    },
    {
      title: "Packed with Goodies",
      icon: faCandyCane,
      content: (
        <>
          Etebase is packed with optional goodies for your every need, including: a full <span className="font-weight-bold">revision history</span> of your data, easy sharing among users, <span className="font-weight-bold">strong integrity</span> protections and more.
        </>
      ),
    },
    {
      title: "Battle Tested",
      icon: faChessRook,
      content: (
        <>
          Etebase uses the widely used and audited <Link href="https://github.com/jedisct1/libsodium">libsodium</Link> behind the scenes, and is based on the code that powers <Link href="https://www.etesync.com">EteSync</Link>. So you know you can rely on it.
        </>
      ),
    },
  ];

  const whyUse = [
    {
      title: "Users care",
      icon: faHeart,
      content: "Users care more and more about the privacy and security of their data. With Etebase, you have them both.",
    },
    {
      title: "Data breach protection",
      icon: faShieldAlt,
      content: "Encrypted data is safe in the case of a breach, and isn't even considered a data-breach under GDPR and HIPAA.",
    },
    {
      title: "Easier compliance",
      icon: faBalanceScale,
      content: "End-to-end encryption makes it much easier to comply with privacy regulation such as GDPR, HIPAA, CCPA, and FERPA.",
    },
    {
      title: "Cryptography is hard",
      icon: faDumbbell,
      content: "Cryptography is different because it's easy to get wrong, hard to get right, and even harder to know if you got it right.",
    },
    {
      title: "Collaborative",
      icon: faUsers,
      content: "Etebase supports sharing data, access control, and everything you need for collaborative editing.",
    },
    {
      title: "Integrated billing",
      icon: faMoneyBill,
      content: "Integrated billing lets you charge users for using your service using a variety of different payment methods.",
      beta: true,
    },
    {
      title: "Performance tuned",
      icon: faTachometerAlt,
      content: "Etebase makes it easy to build performant applications while keeping user data both private and secure.",
    },
    {
      title: "Open-source software",
      icon: faOsi,
      content: <>Etebase is <a href={metadata.links.sources.main}>open-source</a> which means there is no vendor lock-in and anyone can audit the code and contribute.</>,
    },
    {
      title: "Here for the long run",
      icon: faHourglassHalf,
      content: "We started working on EteSync, the first step of our vision, in 2017 and haven't stopped since.",
    },
  ];


  const repos = [
    {
      title: "Server",
      icon: faServer,
      content: "The server component",
      href: metadata.links.sources.server,
    },
    {
      title: "etebase-js",
      icon: faJs,
      content: "The TypeScript/JavaScript client-side library",
      href: metadata.links.sources.js,
    },
    {
      title: "etebase-rs",
      icon: faRust,
      content: "The Rust client-side library",
      href: metadata.links.sources.rust,
    },
    {
      title: "etebase-java",
      icon: faJava,
      content: "The Java/Kotlin client-side library",
      href: metadata.links.sources.java,
    },
    {
      title: "etebase-python",
      icon: faPython,
      content: "The Python client-side library",
      href: metadata.links.sources.python,
    },
    {
      title: "libetebase",
      icon: faCopyright,
      content: "The C client-side library",
      href: metadata.links.sources.c,
    },
    {
      title: "etebase-csharp",
      imageIcon: csharpLogo,
      content: "The C# client-side library",
      href: metadata.links.sources.csharp,
    },
    {
      title: "etebase-go",
      imageIcon: golangLogo,
      content: "The Go client-side library",
      href: metadata.links.sources.go,
      comingSoon: true,
    },
    {
      title: "etebase-ruby",
      imageIcon: rubyLogo,
      content: "The Swift client-side library",
      href: metadata.links.sources.ruby,
      comingSoon: true,
    },
    {
      title: "etebase-swift",
      icon: faSwift,
      content: "The Swift client-side library",
      href: metadata.links.sources.swift,
      comingSoon: true,
    },
  ];

  return (
    <>
      <MetaHeader />

      <div className="Index-section" style={{ backgroundColor: "#fff8eb" }}>
        <Container>
          <Row>
            <Col md>
              <h1>Your end-to-end encrypted backend.</h1>
              <div className="my-4" style={{ fontSize: "1.3em" }}>
                <p>
                  Etebase makes it easy to build end-to-end encrypted applications by taking care of the encryption and its related challenges.
                </p>
                <p>
                  Think Firebase but encrypted in a way that only end-users can access their data.
                </p>
              </div>
              <Link href={metadata.links.docs} className="text-uppercase mr-3 btn btn-outline-dark">Read the docs</Link>
              <Link className="btn btn-success text-uppercase" href={metadata.links.signup}>Sign Up</Link>
            </Col>
            <Col md={7} className="text-center d-flex flex-column pt-5 pt-md-0">
              <Code title="Securely encrypt and upload your data with only a few lines of code." code={mainCode} className="my-auto shadow" />
              <div className="d-flex justify-content-center mt-3">
                <a className="d-flex align-items-center text-decoration-none" style={{ fontSize: "1.3em", color: "inherit" }} href={metadata.links.sources.main} title="Available for…">
                  {repos.map((repo) => (
                    (repo.icon) ? (
                      <FontAwesomeIcon key={repo.title} icon={repo.icon} className="mx-2" />
                    ) : (
                      <img src={repo.imageIcon} key={repo.title} alt={repo.title} style={{ height: "1em" }} className="mx-2" />
                    )
                  ))}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container fluid className="text-center font-weight-bold py-5 Index-section-alt-bg" style={{ backgroundColor: "#f8f9fa", fontSize: "1.5em" }}>
        <p>
          Trusted by
        </p>
        <div>
          <Link className="mx-4" href="https://www.kde.org"><img alt="KDE Logo" src={kdeLogo} style={{ height: "1.8em" }} /></Link>
          <Link className="mx-4" href="https://www.gnome.org"><img alt="GNOME Logo" src={gnomeLogo} style={{ height: "1.8em" }} /></Link>
          <Link className="mx-4 text-nowrap" style={{ color: "inherit", textDecoration: "none" }} href="https://www.tasks.org"><img alt="Tasks.org Logo" src={tasksOrgLogo} style={{ height: "1.8em" }} /><span className="ml-2" style={{ verticalAlign: "middle" }}>Tasks.org</span></Link>
          <Link className="mx-4" href="https://www.etesync.com"><img alt="EteSync Logo" src={etesyncBrand} style={{ height: "1.8em" }} /></Link>
        </div>
      </Container>

      <Container className="Index-section Index-section-first">
        <Row>
          {keyFeatures.map((feature) => (
            <Col key={feature.title} md className="text-center pt-5 pt-md-0">
              <FontAwesomeIcon icon={feature.icon} className="mr-3" style={{ fontSize: "9rem" }} />
              <h3 style={{ fontSize: "2em" }} className="py-4">{feature.title}</h3>
              <p className="text-muted" style={{ fontSize: "1.2em" }}>{feature.content}</p>
            </Col>
          ))}
        </Row>
      </Container>


      <Container className="Index-section">
        <h2 className="text-center">What is Etebase</h2>
        <p className="lead text-center">
          A set of client libraries and a server for building end-to-end encrypted applications.
        </p>
        <Row className="justify-content-md-center">
          <Col md={9}>
            <p className="text-center">
              Build apps quickly without worrying about the infrastructure or the encryption by using any of the Etebase client libraries.
              Everything is <a href={metadata.links.sources.main}>open-source</a>, clients and server, so you can be sure it does what it says on the tin.
              <br />
              Etebase is available on all major platforms and is used by applications on the desktop, mobile and the web.
            </p>
          </Col>
        </Row>
      </Container>


      <Container className="Index-section">
        <h2 className="text-center">Why Etebase</h2>
        <p className="lead text-center">Some of the reasons why you would want to use Etebase.</p>
        <Row>
          {whyUse.map((reason) => (
            <Col key={reason.title} md={4} className="py-3">
              <Card>
                <Card.Body>
                  <Card.Title className="font-weight-bold">
                    <FontAwesomeIcon icon={reason.icon} className="mr-3" />
                    {reason.title}
                    {(reason.beta) && (
                      <small className="text-muted ml-2">(Beta)</small>
                    )}
                  </Card.Title>
                  <Card.Text>{reason.content}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Container className="Index-section text-center">
        <CodeExamplesSection />
      </Container>

      <Container className="Index-section Index-section-last">
        <div className="text-center" id="open-source">
          <h2>Open Source</h2>
          <p className="lead">
            Etebase is fully open source, which means anyone can verify it really does what it says it does, and does it correctly.
          </p>
          <p>
            Follow us on <Link href={metadata.links.sources.github}>Github</Link>. Star and watch each repo for updates.
          </p>
        </div>
        <Row>
          {repos.map((repo) => (
            <Col key={repo.title} md={6} className="py-3">
              <ClickableCard href={repo.href}>
                <Card.Body>
                  <Card.Title className="font-weight-bold text-uppercase">
                    {(repo.icon) ? (
                      <FontAwesomeIcon icon={repo.icon!} className="mr-3" />
                    ) : (
                      <img src={repo.imageIcon} alt={repo.title} style={{ height: "1em" }} className="mr-3" />
                    )}
                    {repo.title}
                    {repo.comingSoon && (
                      <small className="text-muted ml-2">(coming soon)</small>
                    )}
                  </Card.Title>
                  <Card.Text>{repo.content}</Card.Text>
                </Card.Body>
              </ClickableCard>
            </Col>
          ))}
        </Row>
      </Container>


      <Container fluid className="text-center font-weight-bold py-5 Index-section-alt-bg" style={{ fontSize: "1.5em" }}>
        <a href={metadata.links.chat} className="btn btn-outline-dark text-uppercase mr-3">Chat with Us</a>
        <Link className="btn btn-success text-uppercase" href={metadata.links.signup}>Sign Up</Link>
      </Container>

      <Container className="d-flex flex-wrap justify-content-center my-4">
        <h3 className="my-auto p-0 mr-5">Proudly supported by</h3>
        <a href="https://nlnet.nl/" className="site my-3">
          <img height="50" src={nlnetBrand} alt="nlnet"></img>
        </a>
        <span className="mx-4"></span>
        <a href="https://www.ngi.eu/" className="site my-3">
          <img height="50" src={ngiBrand} alt="NGI0 EteSync"></img>
        </a>
        <span className="mx-4"></span>
        <a href="https://www.svix.com/" className="site my-3">
          <img height="50" src={svixBrand} alt="Svix - Webhooks as a Service"></img>
        </a>
      </Container>
    </>
  );
}
