import React from "react";

import { Container } from "react-bootstrap";

import ReactMarkdown from "react-markdown";

import metadata from "../metadata";

import MetaHeader from "../MetaHeader";

export default function CodeOfConduct() {
  const title = "Code of Conduct";

  return (
    <>
      <MetaHeader title={title} />

      <Container className="py-5">
        <div className="article-content">
          <h1 className="display-3 font-weight-bold pb-5">{title}</h1>
          <ReactMarkdown escapeHtml={false} source={`
Like the technical community as a whole, the Etebase team and community is made up of people from all over the world, working on every aspect of the mission - including mentorship, teaching, and connecting people.

Diversity is one of our huge strengths, but it can also lead to communication issues and unhappiness. To that end, we have a few ground rules that we ask people to adhere to. This code applies equally to founders, mentors and those seeking help and guidance.

This isn't intended as a list of things that you can't do, but rather take it in the spirit in which it's intended - a guide to make it easier to enrich all of us and the technical communities in which we participate.

One of Etebase's core values is freedom of speech and preventing censorship, and therefore this code is not intended, and shall never be used to silence views and opinions. It's only here to make sure we all adhere to the same standard of civility.

This code of conduct applies to all spaces run by Etebase. This includes IRC, the mailing lists, the issue tracker, events, and any other forums created or that will be created by the project team which the community uses for communication. In addition to other channels where violations are directed at members of the community such as harassing a member of the community by direct mail.
It does not however apply to how people orchestrate themselves outside the scope of Etebase where they are free to engage in whatever behaviour they choose.

<a id="reporting"></a>
If you believe someone is violating the code of conduct, we ask that you report it by emailing [${metadata.email.conduct}](mailto:${metadata.email.conduct}).

## The Guidelines

- **Be friendly and patient**.
- **Be welcoming.** We strive to be a community that welcomes and supports people of all backgrounds and identities. This includes, but is not limited to members of any race, ethnicity, culture, national origin, colour, immigration status, social and economic class, educational level, sex, sexual orientation, gender identity and expression, age, size, family status, political belief, religion, and mental and physical ability.
- **Be considerate.** Your work will be used by other people, and you in turn will depend on the work of others. Any decision you take will affect users and colleagues, and you should take those consequences into account when making decisions. Remember that we're a world-wide community, so you might not be communicating in someone else's primary language.
- **Be respectful.** Not all of us will agree all the time, but disagreement is no excuse for poor behavior and poor manners. We might all experience some frustration now and then, but we cannot allow that frustration to turn into a personal attack. It’s important to remember that a community where people feel uncomfortable or threatened is not a productive one. Members of the Etebase community should be respectful when dealing with other members as well as with people outside the Etebase community.
- **Be careful in the words that you choose.** We are a community of professionals, and we conduct ourselves professionally. Be kind to others. Do not insult or put down other participants. Harassment and other exclusionary behavior aren't acceptable. This includes, but is not limited to:
  - Violent threats or language directed against another person.
  - Discriminatory jokes and language.
  - Posting sexually explicit or violent material.
  - Posting (or threatening to post) other people's personally identifying information ("doxing").
  - Personal insults, especially those using racist or sexist terms.
  - Unwelcome sexual attention.
  - Advocating for, or encouraging, any of the above behavior.
  - Repeated harassment of others. In general, if someone asks you to stop, then stop.
- **When we disagree, try to understand why.** Disagreements, both social and technical, happen all the time and Etebase is no exception. It is important that we resolve disagreements and differing views constructively. Remember that we’re different. The strength of Etebase comes from its varied community, people from a wide range of backgrounds. Different people have different perspectives on issues. Being unable to understand why someone holds a viewpoint doesn’t mean that they’re wrong. Don’t forget that it is human to err and blaming each other doesn’t get us anywhere. Instead, focus on helping to resolve issues and learning from mistakes.


This code of conduct was originally based on [the Django project's CoC](https://www.djangoproject.com/conduct/).

## Questions?

If you have questions, please feel free to [contact us](mailto:${metadata.email.contact}).
`} />
        </div>
      </Container>
    </>
  );
}
