import React from "react";

import { Switch, Route, useLocation } from "react-router-dom";

import { Navbar, Nav } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

import Footer from "./Footer";
import SupportButton from "./SupportButton";
import metadata from "./metadata";

import Link from "./widgets/SmartLink";

import Index from "./pages/Index";
import About from "./pages/About";
import Terms from "./pages/Terms";
import Imprint from "./pages/Imprint";
import CodeOfConduct from "./pages/CodeOfConduct";
import Pricing from "./pages/Pricing";
import Roadmap from "./pages/Roadmap";
import NotFound404 from "./pages/NotFound404";

import logo from "./logo.svg";

export default function MainRouter() {
  const location = useLocation();

  const headerLinks = [
    {
      title: "Docs",
      href: metadata.links.docs,
    },
    {
      title: "Pricing",
      href: "/pricing",
    },
    {
      title: "Blog",
      href: metadata.links.blog,
    },
    {
      title: "Roadmap",
      href: "/roadmap",
    },
    {
      title: "Source Code",
      href: "/#open-source", // metadata.links.sources.main,
    },
  ];
  return (
    <div className="d-flex flex-column flex-grow-1" style={{ maxWidth: "100%" }}>
      <Navbar bg="light" expand="lg" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px" }}>
        <Link className="navbar-brand" href="/" style={{ fontWeight: "bold" }}>
          <img
            alt={metadata.appName}
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-text-top"
          />
          &nbsp;
          <span className="text-lowercase">
            {metadata.appName}
          </span>
          <small className="text-muted" style={{ fontSize: "60%" }}> by EteSync</small>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav activeKey={location.pathname}>
            {headerLinks.map((link) => (
              <Nav.Link as={Link} key={link.title} href={link.href}>{link.title}</Nav.Link>
            ))}
            <Nav.Link as={Link} className="d-lg-none" href={metadata.links.dashboard}>Log In</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Link href={metadata.links.dashboard} className="btn btn-outline-dark text-uppercase ml-lg-auto d-none d-lg-block mr-3">Log In</Link>
      </Navbar>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/imprint">
          <Imprint />
        </Route>
        <Route path="/code-of-conduct">
          <CodeOfConduct />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/roadmap">
          <Roadmap />
        </Route>
        <Route path="*">
          <NotFound404 />
        </Route>
      </Switch>
      <div className="mt-auto" />
      <Footer />

      <SupportButton />
    </div>
  );
}
