import React from "react";
import { Container } from "react-bootstrap";

import MetaHeader from "../MetaHeader";

import Link from "../widgets/SmartLink";
import metadata from "../metadata";


export default function Pricing() {
  const title = "Pricing";

  return (
    <Container className="py-5">
      <MetaHeader title={title} />

      <h1 className="display-4 font-weight-bold pb-2 text-center">{title}</h1>
      <div className="lead text-center mx-auto pb-3" style={{ fontSize: "1.5em", maxWidth: "30em" }}>
        <p>
          Etebase is <Link href={metadata.links.sources.main}>open-source</Link> so you can run your own server.
          The hosted service is in private beta. For more information please <a href={`mailto:${metadata.email.contact}`}>contact us</a>.
        </p>
      </div>

      <div className="text-center mt-5">
        <Link className="btn btn-primary text-uppercase" href={metadata.links.docs}>Start building</Link>
      </div>
    </Container>
  );
}
