import React from "react";

import { Card } from "react-bootstrap";

import Link from "./SmartLink";

import "bootstrap/dist/css/bootstrap.min.css";

import "./ClickableCard.css";

type PropsType = {
  href?: string;
};

export default function ClickableCard(props: React.PropsWithChildren<PropsType>) {
  const content = (
    <Card className="ClickableCard">
      {props.children}
    </Card>
  );

  if (props.href) {
    return (
      <Link className="link-unstyled p-0 d-block" href={props.href}>
        {content}
      </Link>
    );
  } else {
    return content;
  }
}
