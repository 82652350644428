import React from "react";

import { Row, Col, Button } from "react-bootstrap";

import Code from "../../widgets/Code";

export default function CodeExamplesSection() {
  const [selectedExampleId, setSelectedExampleId] = React.useState(0);
  const codeExamples = [
    {
      title: "List collections",
      longTitle: "Fetch the first 50 collections",
      code: `
const etebase = await Etebase.Account.login("username", "password");
const collectionManager = etebase.getCollectionManager();

// Fetch the first 50 collections
const collections = await collectionManager.list("etebase.files", { limit: 50 });

for (const collection of collections.data) {
  console.log("Got collection:", collection.uid);
}
`.trim(),
    },
    {
      title: "Fetch changed collections",
      longTitle: "Only fetch the collections that changed since we last fetched",
      code: `
const etebase = await Etebase.Account.login("username", "password");
const collectionManager = etebase.getCollectionManager();

// Fetch the first 50 collections
const collections = await collectionManager.list("etebase.files", { limit: 50 });
const stoken = collections.stoken; // Save the sync token for later

// Later on, when we fetch again:
const changedCollections = await collectionManager.list("etebase.files", { stoken });
console.log("Recent changes:", changedCollections.data.length);
`.trim(),
    },
    {
      title: "Modify a collection",
      longTitle: "Fetch a collection by UID and modify it",
      code: `
const etebase = await Etebase.Account.login("username", "password");
const collectionManager = etebase.getCollectionManager();
const collectionUid =  /* ... */; // An existing collection UID

const collection = await collectionManager.fetch(collectionUid);

// Update the collection and upload it
await collection.setContent("New secret!");
await collectionManager.upload(collection);
`.trim(),
    },
    {
      title: "Easily give access",
      longTitle: "Invite another user to join the collection",
      code: `
const etebase = await Etebase.Account.login("username", "password");
const collection = /* ... */; // The collection we want to share

const invitationManager = etebase.getInvitationManager();
const user2 = await invitationManager.fetchUserProfile("username2");

// Show user2.pubkey to the user, verify it's as expected, and then send an invite
await invitationManager.invite(collection, "username2", user2.pubkey,
                               Etebase.CollectionAccessLevel.ReadOnly);

// On user2's device:
// List user2's incoming invitations
const invitations = await user2InvitationManager.listIncoming();
// Show the invitation to the user to verify it's as expected, and then accept
await user2InvitationManager.accept(invitations.data[0]);
`.trim(),
    },
    {
      title: "Complex transactions",
      longTitle: "Enforce data integrity by using transactions",
      code: `
const etebase = await Etebase.Account.login("username", "password");
const collection = /* ... */; // The collection we want to share

// Items are the content of a collection (think files vs filesystem)
const itemManager = etebase.getCollectionManager().getItemManager(collection);

// The items to upload
const items = [item1, item2, item3];
// These items are not uploaded, but upload will be aborted if they have changed
const deps = [item4, item5];
// This function will fail if any of the items or the deps have changed
await itemManager.transaction(items, deps);
// This function will only fail if any of the deps have changed
await itemManager.batch(items, deps);
`.trim(),
    },
    {
      title: "Realtime subscriptions",
      longTitle: "Subscribe to receieve changes in realtime",
      code: `
const etebase = await Etebase.Account.login("username", "password");
const collection = /* ... */; // The collection we want to share

// Items are the content of a collection (think files vs filesystem)
const itemManager = etebase.getCollectionManager().getItemManager(collection);

const subscription = await itemManager.subscribeChanges((items) => {
    console.log("Changed items:", items.data.length);
});
`.trim(),
    },
  ];

  const selectedExample = codeExamples[selectedExampleId];

  return (
    <>
      <h2>Straightforward API, Fully Encrypted</h2>
      <p className="lead">We take care of the encryption and its related challenges so you don't have to.</p>
      <Row className="pt-3">
        <Col lg>
          <Code title={selectedExample.longTitle} code={selectedExample.code} className="my-auto shadow" />
        </Col>

        <Col lg={3}>
          <div className="d-flex flex-column pt-5 pt-lg-0">
            {codeExamples.map((example, index) => (
              <Button variant="outline-primary" className="mb-2" key={example.title} title={example.longTitle} active={index === selectedExampleId} onClick={() => setSelectedExampleId(index)}>{example.title}</Button>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}
