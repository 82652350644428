import React from "react";

import { HashLink as Link } from "react-router-hash-link";

type PropsType = {
  href: string;
} & React.ComponentProps<"a">;

export default function SmartLink(props: React.PropsWithChildren<PropsType>) {
  if (props.href?.startsWith("http") || props.href?.startsWith("mailto:")) {
    return (
      <a target="_blank" rel="noopener noreferrer" {...props} href={props.href}>
        {props.children}
      </a>
    );
  } else if (props.href?.startsWith("#")) {
    const onClick = (event: React.MouseEvent) => {
      const elementId = props.href.substring(1);
      const element = document.getElementById(elementId);
      element?.scrollIntoView();

      event.preventDefault();
    };

    return (
      <a {...props} href={props.href} onClick={onClick}>
        {props.children}
      </a>
    );
  } else {
    return (
      <Link {...props as any} to={props.href}>
        {props.children}
      </Link>
    );
  }
}
