import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faTwitter, faMastodon, faReddit } from "@fortawesome/free-brands-svg-icons";

import Link from "./widgets/SmartLink";

import metadata from "./metadata";

function slugify(name: string) {
  // Credit: https://gist.github.com/mathewbyrne/1280286
  return name.toString().toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

interface FooterLinksType {
  title: string;
  children: {
    title: string;
    href?: string;
    icon?: typeof faGithub;
  }[];
}

const footerLinks: FooterLinksType[] = [
  {
    title: "Company",
    children: [
      {
        title: "About",
      },
      {
        title: "Careers",
        href: `mailto:${metadata.email.jobs}`,
      },
      {
        title: "Open Source",
        href: metadata.links.sources.main,
      },
    ],
  },
  {
    title: "Legal",
    children: [
      {
        title: "Terms",
      },
      {
        title: "Imprint",
      },
    ],
  },
  {
    title: "Resources",
    children: [
      {
        title: "Docs",
        href: metadata.links.docs,
      },
      {
        title: "Blog",
        href: metadata.links.blog,
      },
      {
        title: "Pricing",
      },
      {
        title: "Support",
        href: "/about#contact",
      },
    ],
  },
  {
    title: "Community",
    children: [
      {
        title: "Code of Conduct",
      },
      {
        title: "Github",
        icon: faGithub,
        href: metadata.links.sources.github,
      },
      {
        title: "Twitter",
        icon: faTwitter,
        href: metadata.twitter.link,
      },
      {
        title: "Mastodon",
        icon: faMastodon,
        href: metadata.mastodon.link,
      },
      {
        title: "Reddit",
        icon: faReddit,
        href: metadata.reddit.link,
      },
    ],
  },
];

export default function Footer() {
  return (
    <footer className="main-footer pb-4">
      <Container>
        <Row>
          {footerLinks.map((linkGroup) => (
            <Col key={linkGroup.title} xs={6} md className="pt-5">
              <ul className="list mb-0 mr-5">
                <li className="list-unstyled pb-1"><h5 style={{ fontSize: "1em", fontWeight: 600 }}>{linkGroup.title}</h5></li>
                {linkGroup.children.map((child) => (
                  <li key={child.title} className="list-unstyled pt-2">
                    <Link className="text-muted" href={child.href ?? slugify(child.title)}>
                      {child.icon && (
                        <FontAwesomeIcon icon={child.icon} className="mr-2" />
                      )}
                      {child.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
        <div className="mt-5 text-center">
          © {metadata.author}
        </div>
      </Container>
    </footer>
  );
}
