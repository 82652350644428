import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import metadata from "../metadata";

import MetaHeader from "../MetaHeader";

export default function About() {
  const title = "About";

  return (
    <div className="py-5 w-100">
      <MetaHeader title={title} />

      <Container className="article-content text-center py-5">
        <h1 className="display-4 pb-5">Let's Encrypt Everything!</h1>
        <p>
          We built Etebase to enable developers to easily and quickly build secure encrypted applications.
          Etebase takes care of the encryption and its related challenges so you can focus on what you do best: building great applications.
        </p>
        <p>
          Building encrypted applications is notoriously hard and time consuming. To make matters worth, good cryptography and bad cryptography look the same to the untrained eye, so people are building insecure applications and don't even realise it.
        </p>
        <p>
          With our ever more digital lives, and with data breaches and privacy scandals becoming a common occurrence, securing user data is more important than ever before. This will only keep on growing.
        </p>
      </Container>

      <Container className="article-content text-center py-5">
        <h2 className="display-5">Who are we?</h2>
        <p>
          We are a team of veteran developers and contributors with years of experience working in top-tech companies and building encrypted applications.
        </p>
        <p>
          We are a European company with servers in Europe, and we follow the strict European privacy laws. We design our products in a way that prevents anyone, including us, from accessing your or your users' data.
        </p>
        <p>
          We are proudly supported by the <a href="https://nlnet.nl/PET/">NLnet Foundation</a> (courtesy of NGI0 Discovery and the European Commission DG CNECT's Next Generation Internet programme) and <a href="https://www.svix.com">Svix - Webhooks as a service</a>.
        </p>
      </Container>

      <Container fluid className="my-5 py-5 Index-section-alt-bg" style={{ fontSize: "1.2em" }}>
        <Container>
          <div className="d-flex align-items-center justify-content-center">
            <div>
              <h3>
                Do you care a lot about data privacy and security?
              </h3>
              <p className="mb-0">
                We are always looking for passionate individuals to join our team!
              </p>
            </div>
            <a href={`mailto:${metadata.email.jobs}`} className="ml-5 btn btn-success">Apply Now</a>
          </div>
        </Container>
      </Container>

      <Container className="article-content text-center py-5">
        <h2 id="contact" className="display-5">Contact</h2>
        <p>
          For help with building applications using Etebase, join our developer community chat which is available via <a href={metadata.links.chat}>the web</a>, <a href="ircs://chat.freenode.net:6697/etesync">IRC</a>, or <a href="https://matrix.to/#/#EteSync:matrix.org">Matrix</a>. Or reach out to us on <a href={metadata.twitter.link}>Twitter</a>, <a href={metadata.mastodon.link}>Mastodon</a> and <a href={metadata.reddit.link}>reddit</a>.
        </p>
        <p>
          If you would like to contact us regarding a private matter, you can do so by <a target="_blank" rel="noopener noreferrer" href={`mailto:${metadata.email.contact}`}>email</a>. If your email contains sensitive or private information, please encrypt it using the following PGP key: <a style={{ overflowWrap: "anywhere" }} href={metadata.pgpKey.link}>{metadata.pgpKey.key}</a>.
        </p>

        <p>
          For press related enquiries, please email us at <a href={`mailto:${metadata.email.press}`}>{metadata.email.press}</a>
        </p>
      </Container>

      <Container fluid className="my-5 py-5 Index-section-alt-bg text-center">
        <Container>
          <Row>
            <Col md={6}>
              <h2>Credit and Attribution</h2>
              <p>
                Etebase is open-source software and is made possible thanks to the works of many contributors and open-source projects. For more information please check out our <a href={metadata.links.sources.main}>source code repositories</a>.
              </p>
              <p>
                This website uses icons from <a href="http://fontawesome.io/">Font Awesome</a>, and illustrations from <a href="https://undraw.co/">unDraw</a>.
              </p>
            </Col>
            <Col md={6}>
              <h2 id="warrant-canary">Warrant Canary</h2>
              <p>
                To view the warrant canary, please click <a href="https://www.etesync.com/about/#warrant-canary" title="Warrant canary">here</a>. If you are not sure what a warrant canary is, you can <a href="https://www.eff.org/deeplinks/2014/04/warrant-canary-faq" title="What is a warrant canary?">learn about it here</a>.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
